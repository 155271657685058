<template>
<div>
    <page-header
      :title="'Admins'"
      :sub-heading="true"
      :sub-header="'Dashboard / Admin'"
      :link-name="'Add User'"
      :link-url="'/admin/new'"
    >
    </page-header>

    <full-page-content-container>
        <div class="row">
            <form class="form">
                <div class="form__group">
                    <label for="name" class="form__label">Role Name</label>
                    <input
                    type="text"
                    class="form__input"
                    id="name"
                    v-model="role.name"
                    name="name"
                    />
                </div>

                <div class="form__group">
                    <table class="role">
                        <thead>
                            <tr>
                                <td> Permissions</td>
                                <td>All</td>
                                <td> Create</td>
                                <td> Read</td>
                                <td>Edit</td>
                                <td>Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(resource, index) in role.resources" :key="index">
                                <td>{{resource.code}}</td>
                                <td> <input @input="changePermission(resource.id,'all')" name="all" v-model="resource.permissions.all" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'create')" name="create" v-model="resource.permissions.create" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'read')" name="read" v-model="resource.permissions.read" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'update')" name="update" v-model="resource.permissions.update" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'delete')" name="delete" v-model="resource.permissions.delete" type="checkbox"></td>
                            </tr>
                            
                        </tbody>
                    </table>

                </div>
               <div class="form__group">
                    <ca-button @click.native="EditRole" :disabled="loading">
                    <span>Edit Role</span>
                    <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
                    </ca-button>
                </div>
                </form>
        </div>
    </full-page-content-container>
</div>
</template>

<script>
import CaButton from "../../../components/buttons/CaButton";
import PageHeader from "../../../layouts/components/fullPage/PageHeader";
import axios from "../../../../axios";
import FullPageContentContainer from '../../../layouts/components/fullPage/FullPageContentContainer.vue';

export default {
  name: "EditUser",
  components: { CaButton, FullPageContentContainer, PageHeader },
  data() {
    return {
      loading: false,
      role: {
        name: "",
        display_name: "",
        resources: [
        
      ],
      },
      permissions:[]
     
    };
  },
  methods: {
      getRole(){
           axios.defaults.headers.common["Authorization"] =
            this.$store.state.accessToken;
            axios
                .get(`${this.$store.state.server.requestUri}/admin/roles/${this.$route.params.id}`)
                .then((res) => {
                    this.role.name = res.data.data.name;
                    this.role.display_name = res.data.data.display_name;
                })
                .catch((err) => {
                    console.log(err);
                });
      },
      EditRole(){
        this.role.display_name = this.role.name;
        axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
        axios
            .patch(`${this.$store.state.server.requestUri}/admin/roles/${this.$route.params.id}`, this.role)
            .then((res) => {
                const role=  res.data.data;
                
                this.createPermisions(role.id);
                 this.$toast("Role Udated successfully", {
                    position: "top-center",
                    type: "success",
                });
            })
            .catch((err) => {
                this.$toast(err.response.data.message, {
                    position: "top-center",
                    type: "error",
                });
                console.log(err);
            });

      },
      createPermisions(id){
            axios.defaults.headers.common["Authorization"] =
            this.$store.state.accessToken;
            for (const resource in this.role.resources) {
                  let permissions = [];

                for (const permistion in this.role.resources[resource].permissions) {

                    if (this.role.resources[resource].permissions[permistion]) {
                        if (permistion !== "all") {
                            permissions.push(permistion);
                        }  
                    }
                }
                if(permissions.length===0){
                    let to_del= this.permissions.filter(permission =>{ return permission.resource_id == this.role.resources[resource].id})
                    if (to_del.length > 0) {
                        axios
                            .delete(
                                `${this.$store.state.server.requestUri}/admin/permission/${to_del[0].id}`
                            )
                            .then((res) => {
                                console.log(res);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    
                        
                    }
                }
               console.log(id);
                const form ={
                    
                    resource_id: String(this.role.resources[resource].id),
                    permissions: permissions,
                    role_id: String(id)

                
                }
                if (permissions.length >0) {
                    axios
                    .post(`${this.$store.state.server.requestUri}/admin/permission`, form)
                    .then((res) => {
                        console.log(res)
                        if (res.data.message === "Permission already exist.") {
                           axios
                            .patch(`${this.$store.state.server.requestUri}/admin/permission`, form)
                            .then((res) => {
                                console.log(res)
                            
                            })
                            
                        }
                    })
                }
                
            }
           
        
      },
      getPermissions(){
          let dat = []
        axios.defaults.headers.common["Authorization"] =
            this.$store.state.accessToken;
        axios
            .get(`${this.$store.state.server.requestUri}/admin/permission`)
            .then((res) => {
                // console.log(res.data.data)
            // this.permissions = res.data.data;
             dat = res.data.data.filter(permission => {
                return permission.role_name == this.role.name;
            })
            //  console.log(dat)
            // this.permissions = dat;
         
        
            return dat;
            
        })

          

    },
      changePermission(index, permission){
         let name = this.role.name;
          this.role.name = " ";
          this.role.resources.forEach(element => {
              if (element.id === index) {
                  element.permissions[permission] = !element.permissions[permission];
                  if (permission === "all") {
                      element.permissions.create = element.permissions.all;
                      element.permissions.read = element.permissions.all;
                      element.permissions.update = element.permissions.all;
                      element.permissions.delete = element.permissions.all;
                      this.role.name = name;
                  }
                    
              }
                  
          });
      },
      getResources(){
        const loading = this.$vs.loading();
        this.fetchingData = true;
        axios.defaults.headers.common["Authorization"] =
            this.$store.state.accessToken;
        axios
            .get(`${this.$store.state.server.requestUri}/admin/permission/resources`)
            .then((resp) => {
                let resources = resp.data.data;
                
                // console.log(this.role.resources)
              
                axios.defaults.headers.common["Authorization"] =
                this.$store.state.accessToken;
                axios
                    .get(`${this.$store.state.server.requestUri}/admin/permission`)
                    .then((response) => {
                        axios.defaults.headers.common["Authorization"] =
                        this.$store.state.accessToken;
                        axios
                            .get(`${this.$store.state.server.requestUri}/admin/roles/${this.$route.params.id}`)
                            .then((res) => {
                                
                               let name = res.data.data.name;
                                let display_name = res.data.data.display_name;
                                let dat = response.data.data.filter(per => {
                                    return per.role_name == name;
                                })
                                this.permissions = dat;
                                // console.log(dat)
                                this.role.resources = resources;
                                this.role.name = name;
                                this.role.display_name = display_name;
                                for (const key in this.role.resources) {
                                    this.role.resources[key].permissions = {};
                                    let permission = dat.filter(permission => { return parseInt(permission.resource_id) == resources[key].id})
                                    console.log(permission)
                                    console.log(resources[key].id)
                                    // console.log(dat)
                                    if (dat.filter(permission => { return parseInt(permission.resource_id) == resources[key].id}).length > 0){
                                        
                                        // console.log(permission)
                                        for (const pam in permission) {
                                            if (permission[pam].permissions.includes("create")) {
                                                this.role.resources[key].permissions.create = true;
                                            }else{
                                                this.role.resources[key].permissions.create = false;
                                            }
                                            if (permission[pam].permissions.includes("read")) {
                                                this.role.resources[key].permissions.read = true;
                                            }else{
                                                this.role.resources[key].permissions.read = false;
                                            }
                                            if (permission[pam].permissions.includes("update")) {
                                                this.role.resources[key].permissions.update = true;
                                            }else{
                                                this.role.resources[key].permissions.update = false;
                                            }
                                            if (permission[pam].permissions.includes("delete")) {
                                                this.role.resources[key].permissions.delete = true;
                                            }else{
                                                this.role.resources[key].permissions.delete = false;
                                            }
                                            if (permission[pam].permissions.includes("create") && permission[pam].permissions.includes("read") && permission[pam].permissions.includes("update") && permission[pam].permissions.includes("delete")) {
                                                this.role.resources[key].permissions.all = true;
                                            }else{
                                                this.role.resources[key].permissions.all = false;
                                            }
                                        }

                                        
                                    }else{
                                        this.role.resources[key].permissions.all = false;
                                        this.role.resources[key].permissions.create = false;
                                        this.role.resources[key].permissions.read = false;
                                        this.role.resources[key].permissions.update = false;
                                        this.role.resources[key].permissions.delete = false;
                                    }
                                
                                }
                                console.log(this.role.resources)
                                this.fetchingData = false;
                                loading.close();
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    

                    
                //  console.log(this.role.resources)
                    
                })       
            
        })
           
            
    },
  },
  created() {
    // this.getPermissions();
    // this.getRole();
    this.getResources();
  },
};
</script>

<style scoped>
.role{
    width: 100%;
    min-width: 1500px;
    margin: 10px auto;
}
.role tr{
    width: 100%;
    margin: 10px auto;

}
.role td{
    width: 15%;
    padding: 10px;

}
.check-wrap{
    width: 100%;
    margin: 10px auto;
}

input[type="checkbox"]{
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    border: 1px solid #6C6A70;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-in-out;
}

input[type="checkbox"]:checked{
    background-color: #1D817F;
    border: 1px solid #1D817F;
    color: #fff;
}
input[type="checkbox"]:checked:after{
    color: #fff;
    content: '\2713';
	font-size: 20px;
    position: absolute;
    margin:auto;
    text-align: center;
    top: 3;
    left: 3;


}
.row{
    width: 100%;
}
</style>
